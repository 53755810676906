import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from 'react-helmet';
import styled from "styled-components";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Header } from '../Header';
import { BackButton } from "../Button/Button";

const Container = styled.div`
    height: 100vh;
    font-family: 'Roboto', sans-serif;
`
const TableContainer = styled.table`
    width: 50%;
    margin: 5em auto;
`;

const TableRow = styled.tr`
    background-color: ${props => props.isEven ? '#f1f4f8' : 'white'};
`;

const TableCell = styled.td`
    padding: 1em;
`;

const TableCellRight = styled(TableCell)`
    text-align: right;
`;

const OrganisingCommittee = (props) => {
    const data = useStaticQuery(graphql`
    query {
        allContentfulOrganisingCommitteePageContent {
            nodes {
              tableEntries {
                richTextEn {
					raw
                }
                richTextGr {
					raw
                }
                title
                titleGr
              }
              title
            }
          }
      }
    `);
    const pageContent = data.allContentfulOrganisingCommitteePageContent.nodes[0];
    const isEnglish = props.lang === '' || props.lang === 'En-gb';
    return (
        <Container>
            <BackButton text="Back" />
            <Helmet>
                <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />
            </Helmet>
            <Header>{pageContent.title.toUpperCase()}</Header>
            <TableContainer>
                {pageContent.tableEntries.map((committee, index) => {
                    return (
                        <TableRow isEven={index % 2}>
                            <TableCell>{isEnglish ? committee.title : committee.titleGr}</TableCell>
                            <TableCellRight>{documentToReactComponents(JSON.parse(isEnglish ? committee.richTextEn.raw : committee.richTextGr.raw))}</TableCellRight>
                        </TableRow>
                    );
                })}
            </TableContainer>
        </Container>
    );
}

export default OrganisingCommittee;